import { ProfileCommunity } from '@store/businessCommunity/types';
import { MenuType } from '@features/Menu/types';
import {
  validateResourceBeforeSubmitParams,
  IsFormWasEditParams,
  UploadFileResponse,
  ResourceFile,
  ChangeAttachmentsErrorParams,
  Resource,
} from './types';

import communityPageSlice from '@store/communityPage/communityPage';

import { FIELDS } from '@utils/constants';
import { RESOURCE_FIELD_ERRORS } from './constants';

export const validateResourceBeforeSubmit = ({
  resource,
  setErrors,
}: validateResourceBeforeSubmitParams): boolean => {
  let hasError = false;

  if (!resource.title) {
    setErrors({
      field: 'title',
      value: RESOURCE_FIELD_ERRORS.REQUIRED,
    });
    hasError = true;
  }

  if (resource.title && (resource.title.length < 2 || resource.title.length > 80)) {
    setErrors({
      field: 'title',
      value: RESOURCE_FIELD_ERRORS.LENGTH,
    });
    hasError = true;
  }

  if (
    resource.description &&
    (resource.description.length < 15 || resource.description.length > 280)
  ) {
    setErrors({
      field: FIELDS.DESCRIPTION,
      value: RESOURCE_FIELD_ERRORS.LENGTH,
    });
    hasError = true;
  }

  if (resource.tags?.length > 20) {
    setErrors({
      field: 'tags',
      value: RESOURCE_FIELD_ERRORS.MAX_TAGS,
    });
    hasError = true;
  }

  if (!resource.links.length && !resource.files.length) {
    setErrors({
      field: 'resourceLinks',
      value: RESOURCE_FIELD_ERRORS.EMPTY_ATTACHMENTS,
    });
    hasError = true;
  }

  const istTooManyLinksAndFiles = resource.links.length + resource.files.length > 10;

  if (istTooManyLinksAndFiles) {
    setErrors({
      field: 'resourceLinks',
      value: RESOURCE_FIELD_ERRORS.MANY_ATTACHMENTS,
    });
    hasError = true;
  }

  return hasError;
};

export const isFormWasEdit = ({
  resource,
  isEditResource,
  editingResource,
}: IsFormWasEditParams): boolean => {
  if (isEditResource) {
    return compareResource({ resource, editingResource });
  }

  if (resource.title) return true;

  if (resource.description) return true;

  if (resource.tags.length > 0) return true;

  return resource.links.length > 0;
};

const compareResource = ({
  resource,
  editingResource,
}: Omit<IsFormWasEditParams, 'isEditResource'>): boolean => {
  if (
    resource.id !== editingResource.id ||
    resource.communityId !== editingResource.communityId ||
    resource.title !== editingResource.title ||
    resource.description !== editingResource.description
  ) {
    return true;
  }

  if (compareArrays(resource.tags, editingResource.tags)) {
    return true;
  }

  return compareArrays(resource.links, editingResource.links);
};

const compareArrays = <T>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) {
    return true;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return true;
    }
  }

  return false;
};

export const transformCommunitiesForMenu = (
  approvedCommunities: ProfileCommunity[],
): MenuType[] => {
  return approvedCommunities.map((community) => ({
    id: community.communityId,
    iconName: '',
    title: community.title,
    hasNotification: false,
    link: `/lead/community/${community.name ? community.name : community.communityId}`,
    isBig: false,
    isCommunity: true,
    isPublic: community.isPublic,
    mainCommunityId: community.mainCommunityId,
    page: community.page,
    isActive: community.isActive,
  }));
};

export const createTemporaryFile = (responseFileData: UploadFileResponse): ResourceFile => {
  return {
    fileId: responseFileData.id,
    name: responseFileData.name,
    size: formatFileSize(responseFileData.sizeKb),
    isUploaded: false,
  };
};

export const formatFileSize = (kilobytes: number): string => {
  if (kilobytes === 0) return '0 Кб';

  const megabytes = kilobytes / 1024;
  const gigabytes = megabytes / 1024;

  if (gigabytes >= 1) {
    return `${gigabytes.toFixed(gigabytes % gigabytes === 0 ? 0 : 1)} Гб`;
  } else if (megabytes >= 1) {
    return `${megabytes.toFixed(megabytes % megabytes === 0 ? 0 : 1)} Мб`;
  } else {
    return `${kilobytes.toFixed(kilobytes % kilobytes === 0 ? 0 : 1)} Кб`;
  }
};

export const changeAttachmentsError = ({ resource, dispatch }: ChangeAttachmentsErrorParams) => {
  const isTooManyFilesAndLinks = resource.links.length + resource.files.length > 9;

  dispatch(
    communityPageSlice.actions.setResourceError({
      field: 'resourceLinks',
      value: isTooManyFilesAndLinks ? RESOURCE_FIELD_ERRORS.MANY_ATTACHMENTS : '',
    }),
  );
};

export const transformResources = (resources: Resource[]): Resource[] => {
  return resources.map((item) => ({
    ...item,
    files: item.files.length
      ? item.files.map((file) => ({
          ...file,
          name: String(file.file?.name),
          size: formatFileSize(Number(file.file?.sizeKb)),
          awsKey: file.file?.awsKey,
        }))
      : [],
  }));
};

export const fillResourceFiles = (
  resources: ResourceFile[],
  resource: ResourceFile,
): ResourceFile[] => {
  const findingResource = resources.find((item) => item.name === resource.name);

  return findingResource
    ? resources.map((item) => {
        if (item.name === resource.name) {
          return {
            ...item,
            fileId: resource.fileId,
            isUploaded: resource.isUploaded,
          };
        }

        return item;
      })
    : [...resources, resource];
};
