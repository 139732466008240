import ImgIcon from '@assets/icons/fileExtension/img.svg';
import AudioIcon from '@assets/icons/fileExtension/ic-audio.svg';
import VideoIcon from '@assets/icons/fileExtension/ic-video.svg';
import ArchiveIcon from '@assets/icons/fileExtension/ic-zip.svg';
import FileIcon from '@assets/icons/fileExtension/ic-file.svg';
import PDFIcon from '@assets/icons/fileExtension/ic-pdf.svg';
import WordIcon from '@assets/icons/fileExtension/ic-word.svg';
import ExcelIcon from '@assets/icons/fileExtension/ic-excel.svg';
import PowerPointIcon from '@assets/icons/fileExtension/ic-power-point.svg';
import TxtIcon from '@assets/icons/fileExtension/ic-txt.svg';
import OpenDocumentIcon from '@assets/icons/fileExtension/ic-document.svg';

import { CommunityType } from '@store/businessCommunity/types';
import { CommunityResponse, IconFile } from './types';

export const initialCommunity: CommunityResponse = {
  id: 0,
  title: '',
  url: '',
  isPublic: false,
  communityType: CommunityType.CONNECTED,
  page: {
    id: 0,
    avatar: '',
    slogan: '',
    bgColor: '',
    availableColors: [],
    telegram: '',
    about: '',
    communityId: 0,
  },
  mainCommunityId: 0,
  name: null,
};

export const MESSAGE_EVENTS = {
  VALID: 'valid',
  INVALID: 'invalid',
  HEIGHT: 'height',
};

export const initialResource = {
  id: 0,
  communityId: 0,
  title: '',
  description: '',
  tags: [],
  links: [],
  files: [],
};

const FILE_EXTENSIONS = {
  DOCS: '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .odt, .ods, .odp, .rtf',
  IMAGES: '.jpg, .jpeg, .png, .gif, .svg, .bmp, .tif, .tiff',
  AUDIO: '.mp3, .wav, .aac',
  VIDEO: '.mp4, .mov, .avi',
  ARCHIVE: '.zip, .rar, .7z, .tar',
  OTHER: '.json, .csv',
};

export const EXTENSION_ICON_MAP: Record<string, IconFile> = {
  '.pdf': PDFIcon,
  '.doc': WordIcon,
  '.docx': WordIcon,
  '.xls': ExcelIcon,
  '.xlsx': ExcelIcon,
  '.ppt': PowerPointIcon,
  '.pptx': PowerPointIcon,
  '.txt': TxtIcon,
  '.odt': OpenDocumentIcon,
  '.ods': OpenDocumentIcon,
  '.odp': OpenDocumentIcon,
  '.rtf': OpenDocumentIcon,
  '.jpg': ImgIcon,
  '.jpeg': ImgIcon,
  '.png': ImgIcon,
  '.gif': ImgIcon,
  '.svg': ImgIcon,
  '.bmp': ImgIcon,
  '.tif': ImgIcon,
  '.tiff': ImgIcon,
  '.mp3': AudioIcon,
  '.wav': AudioIcon,
  '.aac': AudioIcon,
  '.mp4': VideoIcon,
  '.mov': VideoIcon,
  '.avi': VideoIcon,
  '.zip': ArchiveIcon,
  '.rar': ArchiveIcon,
  '.7z': ArchiveIcon,
  '.tar': ArchiveIcon,
  '.json': FileIcon,
  '.csv': FileIcon,
};

export const ACCEPTED_FILES = Object.values(FILE_EXTENSIONS).toString();

export const RESOURCE_FIELD_ERRORS = {
  REQUIRED: 'Поле обязательно для заполнения',
  LENGTH: 'от 2 до 80 символов',
  MAX_TAGS: 'Вы можете добавить максимум 20 тегов',
  EMPTY_ATTACHMENTS: 'Вы не можете создать блок не прикрепив материалы',
  MANY_ATTACHMENTS: 'Вы можете добавить до 10 материалов в один блок',
};

export const initialResourceErrors = {
  title: '',
  description: '',
  tags: '',
  resourceLinks: '',
};
