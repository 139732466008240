import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios from '@utils/axios';

import { fetchProfileCommunities } from '@store/businessCommunity/businessCommunity';
import { fetchCalendarEvents, fetchCalendarList } from '@store/calendar/calendar';
import communityMembersSlice, { fetchCommunityPageMembers } from './membersStore/membersStore';
import communityTeamSlice, { fetchCommunityTeam } from './teamStore/teamStore';
import childCommunitySlice, {
  fetchChildCommunities,
} from './childCommunityStore/childCommunityStore';
import paymentSlice, {
  fetchDefaultSubscription,
  fetchPayment,
  fetCurrentSubscriptionData,
} from './paymentStore/paymentStore';
import attachmentLinksSlice, {
  fetchAttachmentLinks,
} from './attachmentLinksStore/attachmentLinksStore';
import securitySlice from '@store/security/secuity';

import {
  transformCommunitiesForMenu,
  transformResources,
  createTemporaryFile,
  changeAttachmentsError,
  formatFileSize,
  fillResourceFiles,
} from './utils';

import { AppDispatch, RootState } from '@store/index';
import { ChangeFieldStateParams } from '@commonTypes/type';
import { ProfileCommunity } from '@store/businessCommunity/types';
import { SnackIcon } from '@store/security/types';
import {
  communityPageState,
  Resource,
  SetResourceLinkParams,
  CreateCommunityResourceParams,
  UpdateCommunityResourceParams,
  FetchFilterCommunityResourcePayload,
  FilterParams,
  CommunityResponse,
  CommunityPage,
  UploadFileResponse,
  ResourceFile,
} from './types';

import {
  initialCommunity,
  initialResource,
  RESOURCE_FIELD_ERRORS,
  initialResourceErrors,
} from './constants';

export const communityPageSlice = createSlice({
  name: 'communityPage',
  initialState: {
    isLoading: false,
    activePage: 0,
    isOpenResourceModal: false,
    isShowFilterModal: false,
    isShowNewResourceModal: false,
    isShowResourceLinkModal: false,
    resource: initialResource,
    resourceError: initialResourceErrors,
    isShowExitModal: false,
    resources: [],
    isShowRemoveModal: false,
    removedResourceId: null,
    menuCommunities: [],
    currentCommunity: initialCommunity,
    pageData: initialCommunity.page,
    currentCommunityTags: [],
    searchValue: '',
    initialResources: [],
    chosenTags: [],
    isEditMode: false,
    isShowMemberMenu: false,
    isShowLeaveCommunityModal: false,
    isManager: false,
    isEditResource: false,
    editingResource: initialResource,
    isFileUpload: false,
  } as communityPageState,
  reducers: {
    setIsLoading: (state, { payload }: { payload: boolean }) => {
      state.isLoading = payload;
    },
    setActivePage: (state, { payload }: { payload: number }) => {
      state.activePage = payload;
    },
    setIsOpenResourceModal: (state, { payload }: { payload: boolean }) => {
      state.isOpenResourceModal = payload;
    },
    setIsShowFilterModal: (state, { payload }: { payload: boolean }) => {
      state.isShowFilterModal = payload;
    },
    setIsShowNewResourceModal: (state, { payload }: { payload: boolean }) => {
      state.isShowNewResourceModal = payload;
    },
    setIsShowResourceLinkModal: (state, { payload }: { payload: boolean }) => {
      state.isShowResourceLinkModal = payload;
    },
    setResource: (state, { payload }: { payload: Resource }) => {
      state.resource = payload;
    },
    setResourceLink: (state, { payload }: { payload: SetResourceLinkParams }) => {
      const findingResource = state.resource.id === payload.resourceId;

      if (findingResource) {
        const findingResourceLink = state.resource.links.find(
          (link) => link.id === payload.resource.id,
        );

        state.resource = findingResourceLink
          ? {
              ...state.resource,
              links: state.resource.links.map((link) => {
                if (link.id === payload.resource.id) {
                  return {
                    ...link,
                    url: payload.resource.url,
                    title: payload.resource.title,
                  };
                }

                return link;
              }),
            }
          : {
              ...state.resource,
              links: [...state.resource.links, payload.resource],
            };
      }

      state.isShowResourceLinkModal = false;
      state.isShowNewResourceModal = true;
    },
    removeResourceLink: (state, { payload }: { payload: number }) => {
      state.resource = {
        ...state.resource,
        links: state.resource.links.filter((link) => link.id !== payload),
      };

      const isTooManyFilesAndLinks = state.resource.links.length + state.resource.files.length > 10;

      state.resourceError = {
        ...state.resourceError,
        resourceLinks: isTooManyFilesAndLinks ? RESOURCE_FIELD_ERRORS.MANY_ATTACHMENTS : '',
      };
    },
    setResourceError: (state, { payload }: { payload: ChangeFieldStateParams }) => {
      state.resourceError = {
        ...state.resourceError,
        [payload.field]: payload.value,
      };
    },
    resetResourceError: (state) => {
      state.resourceError = initialResourceErrors;
    },
    setIsShowExitModal: (state, { payload }: { payload: boolean }) => {
      state.isShowExitModal = payload;
    },
    setResources: (state, { payload }: { payload: Resource[] }) => {
      state.resources = transformResources(payload);
    },
    setInitialResources: (state, { payload }: { payload: Resource[] }) => {
      state.initialResources = transformResources(payload);
    },
    setIsShowRemoveModal: (state, { payload }: { payload: boolean }) => {
      state.isShowRemoveModal = payload;
    },
    setRemovedResourceId: (state, { payload }: { payload: number | null }) => {
      state.removedResourceId = payload;
    },
    filterResources: (state, { payload }: { payload: Resource[] }) => {
      state.resources = payload;
      state.removedResourceId = null;
      state.isShowRemoveModal = false;
    },
    setMenuCommunities: (state, { payload }: { payload: ProfileCommunity[] }) => {
      state.menuCommunities = transformCommunitiesForMenu(payload);
    },
    setPageData: (state, { payload }: { payload: CommunityPage }) => {
      state.pageData = payload;
    },
    setCurrentCommunity: (state, { payload }: { payload: CommunityResponse }) => {
      state.currentCommunity = payload;
    },
    setCurrentCommunityTags: (state, { payload }: { payload: string[] }) => {
      state.currentCommunityTags = payload;
    },
    setSearchValue: (state, { payload }: { payload: string }) => {
      state.searchValue = payload;
    },
    setChosenTags: (state, { payload }: { payload: string[] }) => {
      state.chosenTags = payload;
    },
    setIsEditMode: (state, { payload }: { payload: boolean }) => {
      state.isEditMode = payload;
    },
    setIsShowMemberMenu: (state, { payload }: { payload: boolean }) => {
      state.isShowMemberMenu = payload;
    },
    setIsShowLeaveCommunityModal: (state, { payload }: { payload: boolean }) => {
      state.isShowLeaveCommunityModal = payload;
    },
    setIsManager: (state, { payload }: { payload: boolean }) => {
      state.isManager = payload;
    },
    setIsEditResource: (state, { payload }: { payload: boolean }) => {
      state.isEditResource = payload;
    },
    setEditingResource: (state, { payload }: { payload: Resource }) => {
      state.editingResource = payload;
    },
    openEditResource: (state, { payload }: { payload: number }) => {
      const findingResource = state.resources.find((resource) => resource.id === payload);

      if (findingResource) {
        state.resource = findingResource;
        state.isOpenResourceModal = true;
        state.isShowNewResourceModal = true;
        state.isEditResource = true;
        state.editingResource = {
          id: findingResource.id,
          communityId: findingResource.communityId,
          title: findingResource.title,
          description: findingResource.description,
          tags: findingResource.tags,
          links: findingResource.links,
          files: findingResource.files,
        };
      }
    },
    setResourceFiles: (state, { payload }: { payload: ResourceFile }) => {
      state.resource = {
        ...state.resource,
        files: fillResourceFiles(state.resource.files, payload),
      };
    },
    removeResourceFile: (state, { payload }: { payload: number }) => {
      state.resource = {
        ...state.resource,
        files: state.resource.files.filter((item) => item.fileId !== payload),
      };

      const isTooManyFilesAndLinks = state.resource.links.length + state.resource.files.length > 10;

      state.resourceError = {
        ...state.resourceError,
        resourceLinks: isTooManyFilesAndLinks ? RESOURCE_FIELD_ERRORS.MANY_ATTACHMENTS : '',
      };
    },
    resetState: (state) => {
      state.resource = initialResource;
      state.resourceError = initialResourceErrors;
      state.removedResourceId = null;
      state.currentCommunityTags = [];
      state.searchValue = '';
      state.activePage = 0;
      state.resources = [];
      state.isEditMode = false;
      state.isOpenResourceModal = false;
      state.isShowFilterModal = false;
      state.isShowNewResourceModal = false;
      state.isShowResourceLinkModal = false;
      state.isShowExitModal = false;
      state.isShowRemoveModal = false;
      state.initialResources = [];
      state.chosenTags = [];
      state.isShowMemberMenu = false;
      state.isShowLeaveCommunityModal = false;
      state.currentCommunity = initialCommunity;
      state.pageData = initialCommunity.page;
      state.isManager = false;
      state.isEditResource = false;
      state.editingResource = initialResource;
    },
    resetResourceModalState: (state) => {
      state.resource = initialResource;
      state.resourceError = initialResourceErrors;
    },
  },
});

export const fetchCommunityPageData =
  (communityId: number | string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    resetAllCommunityStores(dispatch);

    dispatch(communityPageSlice.actions.setIsLoading(true));
    const userManagerCommunityId = getState().auth.user?.managedCommunityId;
    const userId = getState().auth.user?.id;

    if (isNaN(+communityId)) {
      const { data: communities } = await axios.get(`/communities`, {
        params: {
          name: communityId,
          page: 1,
        },
      });
      communityId = communities.data[0].id;
    }

    try {
      const { data: community } = await axios.get<CommunityResponse>(`/communities/${communityId}`);

      if (community) {
        const { page } = community;

        if (!page) return null;

        if (userManagerCommunityId) {
          const isManager = page.communityId === userManagerCommunityId;

          dispatch(communityPageSlice.actions.setIsManager(isManager));
        }

        const promises = [
          () => dispatch(fetchProfileCommunities(userId)),
          () => dispatch(fetchCommunityPageMembers({ communityId: community.id })),
          () => dispatch(fetchCommunityTeam(community.id)),
          () => dispatch(fetchChildCommunities({ mainCommunityId: community.id })),
          () => dispatch(fetchDefaultSubscription(community.id)),
          () => dispatch(fetCurrentSubscriptionData(community.id)),
          () => dispatch(fetchPayment(community.id)),
          () => dispatch(fetchAttachmentLinks(community.id)),
          () => dispatch(fetchCalendarList()),
          () => dispatch(fetchCalendarEvents(community.title)),
        ];

        await executeInOrder(promises);

        dispatch(communityPageSlice.actions.setPageData(page));
        dispatch(communityPageSlice.actions.setCurrentCommunity(community));
      }
    } catch (e) {
      console.log('Не удалось запросить сообщество');
    } finally {
      dispatch(communityPageSlice.actions.setIsLoading(false));
    }
  };

const resetAllCommunityStores = (dispatch: AppDispatch) => {
  dispatch(communityMembersSlice.actions.resetState());
  dispatch(communityTeamSlice.actions.resetState());
  dispatch(childCommunitySlice.actions.resetState());
  dispatch(paymentSlice.actions.resetPayment());
  dispatch(attachmentLinksSlice.actions.resetState());
};

const executeInOrder = async (
  promises: ((() => Promise<null | undefined>) | (() => Promise<void>))[],
) => {
  for (const promise of promises) {
    await promise();
  }
};

export const fetchCommunityPageResources =
  (communityId: number) => async (dispatch: AppDispatch) => {
    dispatch(communityPageSlice.actions.setIsLoading(true));

    try {
      const resources = await axios.get<Resource[]>(`/communities/materials/${communityId}`);

      if (resources.data.length) {
        dispatch(communityPageSlice.actions.setResources(resources.data));
        dispatch(communityPageSlice.actions.setInitialResources(resources.data));
      } else {
        dispatch(communityPageSlice.actions.setResources([]));
      }
    } catch (e) {
      console.log('Не удалось запросить материалы');
    } finally {
      dispatch(communityPageSlice.actions.setIsLoading(false));
    }
  };

export const createCommunityResource =
  (params: CreateCommunityResourceParams) => async (dispatch: AppDispatch) => {
    dispatch(communityPageSlice.actions.setIsLoading(true));

    try {
      const response = await axios.post(
        `/communities/materials/${params.communityId}`,
        params.data,
      );

      if (response.status === 201) {
        dispatch(communityPageSlice.actions.resetResourceModalState());
        dispatch(fetchCommunityPageResources(params.communityId));
      }
    } catch (e) {
      console.log('Не удалось создать материал');
    } finally {
      dispatch(communityPageSlice.actions.setIsLoading(false));
    }
  };

export const updateCommunityResource =
  (params: UpdateCommunityResourceParams) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(communityPageSlice.actions.setIsLoading(true));

    const resources = getState().communityPage.resources;
    const findingResource = resources.find((resource) => resource.id === params.resourceId);

    if (!findingResource) return null;

    const communityId = findingResource.communityId;

    if (!communityId) return null;

    try {
      const response = await axios.patch(
        `/communities/materials/${communityId}/${findingResource.id}`,
        params.data,
      );

      if (response.status === 200) {
        dispatch(fetchCommunityPageResources(communityId));
        dispatch(communityPageSlice.actions.setIsEditResource(false));
        dispatch(communityPageSlice.actions.setIsOpenResourceModal(false));
        dispatch(communityPageSlice.actions.setIsShowNewResourceModal(false));
        dispatch(communityPageSlice.actions.setEditingResource(initialResource));
        dispatch(communityPageSlice.actions.setResource(initialResource));
      }
    } catch (e) {
      console.log('Не удалось обновить материал');
    } finally {
      dispatch(communityPageSlice.actions.setIsLoading(false));
    }
  };

export const removeCommunityResource =
  (resourceId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(communityPageSlice.actions.setIsLoading(true));

    const resources = getState().communityPage.resources;
    const findingResource = resources.find((resource) => resource.id === resourceId);
    const isEditResource = getState().communityPage.isEditResource;

    if (!findingResource) return null;

    const communityId = findingResource.communityId;

    if (!communityId) return null;

    try {
      const response = await axios.delete(
        `/communities/materials/${communityId}/${findingResource.id}`,
      );

      if (response.status === 200) {
        dispatch(fetchCommunityPageResources(communityId));

        dispatch(
          communityPageSlice.actions.filterResources(
            resources.filter((resource) => resource.id !== findingResource.id),
          ),
        );

        dispatch(communityPageSlice.actions.setIsShowRemoveModal(false));
        dispatch(communityPageSlice.actions.setRemovedResourceId(null));

        if (isEditResource) {
          dispatch(communityPageSlice.actions.setIsEditResource(false));
          dispatch(communityPageSlice.actions.setEditingResource(initialResource));
          dispatch(communityPageSlice.actions.setResource(initialResource));
        }
      }
    } catch (e) {
      console.log('Не удалось удалить материал');
    } finally {
      dispatch(communityPageSlice.actions.setIsLoading(false));
    }
  };

export const fetchCommunityPageTags = (communityId: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get<string[]>(`/communities/materials/${communityId}/tags`);

    if (response.data.length) {
      dispatch(communityPageSlice.actions.setCurrentCommunityTags(response.data));
    }
  } catch (e) {
    console.log('Не удалось получить теги');
  }
};

export const fetchFilterCommunityResources =
  ({ communityId, tags, search }: FetchFilterCommunityResourcePayload) =>
  async (dispatch: AppDispatch) => {
    try {
      const params: FilterParams = {};

      if (search) {
        params.search = search;
      }

      if (tags) {
        params.tags = tags;
      }

      const response = await axios.get(`/communities/materials/${communityId}/filter`, { params });

      if (response.data.data) {
        dispatch(communityPageSlice.actions.setResources(response.data.data));

        if (tags?.length) {
          dispatch(communityPageSlice.actions.setResources(response.data.data));
          dispatch(communityPageSlice.actions.setIsShowFilterModal(false));
          dispatch(communityPageSlice.actions.setChosenTags(tags));
        }
      }
    } catch (e) {
      console.log('Не удалось получить отфильтрованные данные');
    }
  };

export const uploadFileResource =
  (file: FormData, signal: AbortSignal, fileInfo: File) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const resource = getState().communityPage.resource;

    if (fileInfo instanceof File) {
      dispatch(
        communityPageSlice.actions.setResourceFiles({
          fileId: fileInfo.lastModified,
          name: fileInfo.name,
          size: formatFileSize(fileInfo.size / 1024),
          isUploaded: true,
        }),
      );
    }

    changeAttachmentsError({ resource, dispatch });

    try {
      const response = await axios.post<UploadFileResponse>('/files', file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        signal,
      });

      if (response.data) {
        dispatch(communityPageSlice.actions.setResourceFiles(createTemporaryFile(response.data)));
      }
    } catch (err) {
      const error = err as AxiosError;

      if (error.response?.status === 400) {
        dispatch(
          securitySlice.actions.setSnackStatus({
            icon: SnackIcon.error,
            text: `Неподдерживаемый формат файла ${fileInfo.name || ''}`,
          }),
        );
        dispatch(securitySlice.actions.setIsShowSnackbar(true));
      }

      if (error.response?.status === 500) {
        dispatch(
          securitySlice.actions.setSnackStatus({
            icon: SnackIcon.error,
            text: `Не удалось загрузить файл на сервер`,
          }),
        );
        dispatch(securitySlice.actions.setIsShowSnackbar(true));
      }

      dispatch(communityPageSlice.actions.removeResourceFile(fileInfo.lastModified));
    }
  };

export default communityPageSlice;
